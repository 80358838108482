import React from 'react';

const Robots = (
  robots?:
    | 'index, follow'
    | 'noindex, follow'
    | 'index, nofollow'
    | 'noindex, nofollow'
    | ''
    | undefined
    | null
) => {
  /*if (process.env.IS_DEV) {
    return <meta name="robots" content="noindex, nofollow" />;
  }*/

  return <meta name="robots" content={robots || 'index, follow'} />;
};

export default Robots;
